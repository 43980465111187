import { CurrencyExchange, EmojiEvents, Feed, Leaderboard, Menu, Notifications, Speed, SwapHoriz, WebAsset } from "@mui/icons-material";
import { AppBar, Box, Button, Divider, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Toolbar, Typography } from "@mui/material";
import { useState } from "react";
import { Link, Outlet } from "react-router-dom";

function createSidebarButton(name, icon, to) {
    return (
        <ListItem icon="" key={name} disablePadding>
            <ListItemButton component={Link} to={to}>
                <ListItemIcon sx={{minWidth:35}}>{icon}</ListItemIcon>
                <ListItemText primary={name}/>
            </ListItemButton>
        </ListItem>
    )
}

export const Layout = ({children}) => {
    const [collapsed, setCollapsed] = useState(false)
    const sidebarWidth = collapsed ? 0 : "15vw"

    const handleCollapsing = () => setCollapsed(!collapsed)
    
    return (
        <Box sx={{ display: "flex", height:"100vh"}}>
            <AppBar sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <Toolbar>
                    <Button
                        color="inherit"
                        onClick={handleCollapsing}
                    >
                        <Menu sx={{ mr: 1 }}/>
                        <Typography noWrap>
                            CoinManager
                        </Typography>
                    </Button>
                </Toolbar>
            </AppBar>
            <Drawer open={!collapsed} variant="persistent" anchor="left" sx={{
                width: sidebarWidth,
                maxWidth:250,
                minWidth: (collapsed ? 0 : 180),
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: { maxWidth:250, minWidth: (collapsed ? 0 : 180), width: sidebarWidth, boxSizing: "border-box" },
            }}>
                <Toolbar/>
                <Box>
                    <List>
                        <Divider>Core</Divider>
                        {createSidebarButton("Dashboard", (<Speed/>), "/core/dashboard")}
                        {createSidebarButton("Trophy", (<EmojiEvents/>), "/core/trophy")}
                        <Divider>Coins</Divider>
                        {createSidebarButton("Overview", (<WebAsset/>), "/coins/overview")}
                        {createSidebarButton("Transactions", (<SwapHoriz/>), "/coins/transactions")}
                        {createSidebarButton("Alerts", (<Notifications/>), "/coins/alerts")}
                        {createSidebarButton("Statistics", (<Leaderboard/>), "/coins/stats")}
                        <Divider>External</Divider>
                        {createSidebarButton("News", (<Feed/>), "/external/news")}
                        {createSidebarButton("Exchanges", (<CurrencyExchange/>), "/external/exchanges")}

                    </List>
                </Box>
            </Drawer>
            <Box display="flex" flexDirection="column" sx={{flexGrow: 1}}>
                <Box sx={{ height:"calc(100vh - 64px)"}}>
                    <Box component="main" sx={{ p: 3 }}>
                        <Toolbar />
                        <Outlet /> {/*child elements based on routing*/}
                    </Box>
                </Box>
                <AppBar component="footer" sx={{ position:"relative", justifyContent: 'flex-end'}}>
                    <Toolbar>
                        <Typography variant="caption" noWrap>
                            © 2024 - CoinManager
                        </Typography>
                    </Toolbar>
                </AppBar>
            </Box>


        </Box>        
    )
};