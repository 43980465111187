import { Routes, Route } from 'react-router-dom';
import { CoinsOverviewScene, DashboardScene, Layout, UnavailableScene } from './scenes'
import { CssBaseline, createTheme, ThemeProvider } from '@mui/material';
import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';

function App() {
  //determine if dark mode or light mode should be used
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  //create base theme that checks for palette mode
  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: prefersDarkMode ? 'dark' : 'light',
        },
      }),
    [prefersDarkMode],
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline/>
      <Routes>
        <Route path="/" element={<Layout/>}>

          <Route path="core">
            <Route path="dashboard" element={<DashboardScene />} />
          </Route>
          
          <Route path="coins">
            <Route path="overview" element={<CoinsOverviewScene/>}/>
          </Route>

          <Route path='*' element={<UnavailableScene/>} />
        </Route>
      </Routes>
    </ThemeProvider>
  )
};

export default App;
