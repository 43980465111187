import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import axios from "axios";

export const CoinsOverviewScene = () => {
    const [data, setData] = useState(null)
    const columns = [
        { id: "name", label:"Coin" },
        { id: "symbol", label:"Symbol" },
        { id: "explorers", label:"Explorer" }
    ]

    //  fetch data
    const fetchData = async () => {
        try {
            const response = await axios.get("https://coinmanager.perisutti.nl/cma/output/fetch.php?fetch=ALL");
            setData(response.data.data)
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    //  call fetch
    useEffect(() => {
        fetchData()
    }, [])

    //  return coin overview content
    return (
            <Box>
                <Typography variant="h6">Coin Overview</Typography>
                   <TableContainer sx={{ height:"calc(100vh - 180px)"}}>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>{ columns.map(col => (<TableCell key={col.id}>{col.label}</TableCell>)) }</TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    data === null ? null : data.map(row => {
                                        return (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                                {
                                                    columns.map(col => {
                                                        const value = row[col.id]
                                                        return (<TableCell key={col.id}>{value}</TableCell>)
                                                    })
                                                }
                                            </TableRow>
                                    )})
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
            </Box>
    )
};