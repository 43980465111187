import { Box, Paper, Typography } from "@mui/material";

export const DashboardScene = () => {
    //  return main page content
    return (
        <Box>
            <Typography variant="h6">Core Dashboard</Typography>
            <Paper>
               Hello World
           </Paper>
        </Box>
    )
};